var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var CaseFollowRecordService = /** @class */ (function (_super) {
    __extends(CaseFollowRecordService, _super);
    function CaseFollowRecordService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CaseFollowRecordService.prototype.setContactResult = function (data) {
        // 处理联系结果为字符串
        // 如果设置数据源的控件类型错误，可能导致数据类型错误
        var contactResult = data.contactResult;
        if (contactResult instanceof Array) {
            // 正常的级联产生的数据处理
            contactResult = contactResult.join("/");
        }
        var contactState = data.contactState;
        if (contactState instanceof Array) {
            contactState = contactState.join("/");
        }
        return __assign(__assign({}, data), { contactResult: contactResult, contactState: contactState });
    };
    /**
     * 修改调记
     */
    CaseFollowRecordService.prototype.updateCaseFollowRecord = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.updateCaseFollowRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 添加调记
     */
    CaseFollowRecordService.prototype.addCaseFollowRecord = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.addCaseFollowRecord,
            data: this.setContactResult(data),
            loading: loading,
        });
    };
    /**
     * 添加查找记录
     */
    CaseFollowRecordService.prototype.addFindRecord = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.addFindRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 添加其他调记
     */
    CaseFollowRecordService.prototype.addOtherRecord = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.addOtherRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 调记查询
     */
    CaseFollowRecordService.prototype.getAllCaseFollowRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getAllCaseFollowRecord,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查找记录查询
     */
    CaseFollowRecordService.prototype.getAllFindRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getAllFindRecord,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 其他调记查询
     */
    CaseFollowRecordService.prototype.getAllOtherRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getAllOtherRecord,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 删除调记
     */
    CaseFollowRecordService.prototype.deleteCaseFollowRecord = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.deleteCaseFollowRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除标注
     */
    CaseFollowRecordService.prototype.deleteTag = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.deleteTag,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询标注
     */
    CaseFollowRecordService.prototype.getAllTag = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getAllTag,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 添加标注
     */
    CaseFollowRecordService.prototype.addTag = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.addTag,
            data: data,
            loading: loading,
        });
    };
    /**
     * 工单回复
     */
    CaseFollowRecordService.prototype.replyOtherRecord = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.replyOtherRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 根据类型查询批注
     */
    CaseFollowRecordService.prototype.getCommentByType = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getCommentByType,
            data: data,
            loading: loading,
        });
    };
    /**
     * 根据类型查询批注
     */
    CaseFollowRecordService.prototype.getDebtFollowRecord = function (data) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getDebtFollowRecord,
            data: data,
        });
    };
    /**
     * 敏感词校验
     */
    CaseFollowRecordService.prototype.checkString = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.checkString,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询委前调记
     */
    CaseFollowRecordService.prototype.getAllPreRecord = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.getAllPreRecord,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 导出
     */
    CaseFollowRecordService.prototype.export = function (data, loading) {
        return this.netService.send({
            server: businessService.caseFollowRecordController.export,
            data: data,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseFollowRecordService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseFollowRecordService.prototype, "checkString", null);
    __decorate([
        Debounce()
    ], CaseFollowRecordService.prototype, "getAllPreRecord", null);
    __decorate([
        Debounce()
    ], CaseFollowRecordService.prototype, "export", null);
    return CaseFollowRecordService;
}(Service));
export { CaseFollowRecordService };
